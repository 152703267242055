<template>
    <div class="scan-page">
        <div>
            <div class="header-menu">
                <span>
                    <img src="@/assets/images/sidebar/arrow-left.png" alt class="menu-info" @click.prevent="goBack()" />
                </span>
                <div class="product-name">
                    <span> 扫一扫 </span>
                </div>
                <div>
                    <img
                        src="@/assets/images/sidebar/edit-box-line@2x.png"
                        alt
                        class="menu-info"
                        @click.prevent="tipsSubmit(1)"
                    />
                </div>
            </div>
            <p>
                Last result: <b>{{ result }}</b>
            </p>

            <qrcode-stream :camera="camera" @decode="onDecode" @init="onInit">
                <div>
                    <p>
                        Last result: <b>{{ result }}</b>
                    </p>
                </div>
            </qrcode-stream>
        </div>
    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
    components: { QrcodeStream },

    data() {
        return {
            camera: 'auto',
            result: null,
            showScanConfirmation: false,
        }
    },

    methods: {
        async onInit(promise) {
            try {
                await promise
            } catch (e) {
                console.error(e)
            } finally {
                this.showScanConfirmation = this.camera === 'off'
            }
        },

        async onDecode(content) {
            this.result = content

            this.pause()
            await this.timeout(500)
            this.unpause()
        },

        unpause() {
            this.camera = 'auto'
        },

        pause() {
            this.camera = 'off'
        },

        timeout(ms) {
            return new Promise((resolve) => {
                window.setTimeout(resolve, ms)
            })
        },
        goBack() {
            this.$router.go(-1)
        },
    },
}
</script>

<style lang="less" scoped>
.scan-page {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: url(../../assets/images/report/history_bj.png);
    background-size: 100% 100%;
    position: relative;
    top: 0;

    .header-menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 15px;
        width: 100%;
        top: 0;
        z-index: 100;
        > span {
            img {
                display: block;
            }
        }
        .menu-info {
            width: 24px;
            height: 24px;
        }
        > div {
            img {
                position: relative;
                top: 2px;
                display: inline-block;
            }
        }
        .product-name {
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            line-height: 15px;
            font-weight: 400;
            background: linear-gradient(90deg, #ffffff, #ffffff);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}
</style>
